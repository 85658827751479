const QUERIES = {
  CONTACT_LIST: 'contact-list',
  CONTACT_GROUP_LIST: 'contact-group-list',
  PROPERTY_LIST: 'property-list',
  REMINDER_LIST: 'reminder-list',
  MYPROPERTY_LIST: 'myproperty-list',
  LISTINGS: 'listings',
  ACTIVITIES: 'activities',
};

export { QUERIES };
