import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const AccountSettingEditIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.3333 5.41675H13.3333"
      stroke="#4C4F59"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.99999 5.41675H1.66666"
      stroke="#4C4F59"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.33332 8.33333C9.94415 8.33333 11.25 7.0275 11.25 5.41667C11.25 3.80584 9.94415 2.5 8.33332 2.5C6.72249 2.5 5.41666 3.80584 5.41666 5.41667C5.41666 7.0275 6.72249 8.33333 8.33332 8.33333Z"
      stroke="#4C4F59"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M18.3333 14.5833H15"
      stroke="#4C4F59"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.66666 14.5833H1.66666"
      stroke="#4C4F59"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M11.6667 17.5001C13.2775 17.5001 14.5833 16.1942 14.5833 14.5834C14.5833 12.9726 13.2775 11.6667 11.6667 11.6667C10.0558 11.6667 8.75 12.9726 8.75 14.5834C8.75 16.1942 10.0558 17.5001 11.6667 17.5001Z"
      stroke="#4C4F59"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default AccountSettingEditIcon;
