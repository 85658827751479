import React, { useState } from 'react';
import { Button, Drawer, Box, Typography, IconButton, Stack, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { drawerBody } from './details-style.sx';
import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { PasswordChange } from '../../../api/account-settings/models';
import { updatePassword } from '../../../api/account-settings/requests';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface FormData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const UpdateUserDrawer = ({ isOpen, onClose }: Props) => {
  const { handleSubmit, control, reset } = useForm<FormData>();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);

  const mutation = useMutation(updatePassword, {
    onSuccess: () => {
      queryClient.invalidateQueries('userDetails');
      onClose();
      reset();
    },
    onError: (err: any) => {
      setError(`Failed to update password: ${err.message}`);
    },
  });

  const onSubmit = (data: FormData) => {
    if (data.newPassword !== data.confirmPassword) {
      setError('New password and confirm password do not match');
      return;
    }

    const request: PasswordChange = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    mutation.mutate(request);
  };

  return (
    <Drawer anchor="right" variant="temporary" open={isOpen} onClose={onClose}>
      <Box sx={drawerBody}>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h6">Account Settings</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        {/* Form Fields */}
        <Stack spacing={3} sx={{ flex: 1 }}>
          <Controller
            name="oldPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Old Password"
                type="password"
                variant="outlined"
                error={!!error}
                helperText={error || ''}
              />
            )}
          />
          <Controller
            name="newPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="New Password"
                type="password"
                variant="outlined"
                error={!!error}
                helperText={error || ''}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Confirm Password"
                type="password"
                variant="outlined"
                error={!!error}
                helperText={error || ''}
              />
            )}
          />
        </Stack>
        {/* Update Button */}
        <Box sx={{ mt: 4 }}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            sx={{
              bgcolor: 'grey.300',
              color: 'text.primary',
              '&:hover': {
                bgcolor: 'grey.400',
              },
              py: 1.5,
            }}
            onClick={handleSubmit(onSubmit)}
            disabled={mutation.isLoading}
          >
            {mutation.isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default UpdateUserDrawer;
