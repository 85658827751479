import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FlyerIcon = (props: SvgIconProps) => (
  <SvgIcon
    sx={{
      width: '16px',
      height: '16px',
      '& path': {
        fill: 'currentColor',
      },
    }}
    viewBox="0 0 511.999 511.999"
    fontSize="medium"
    {...props}
  >
    <g>
      <g>
        <polyline
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 30,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          points="&#10;&#9;&#9;&#9;171.445,455.581 15,455.581 15,15.001 340.557,15.001 340.557,97.131 &#9;&#9;"
        />

        <rect
          x="171.445"
          y="97.131"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 30,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          width="325.557"
          height="399.87"
        />
        <g>
          <line
            style={{
              fill: 'none',
              stroke: '#000000',
              strokeWidth: 30,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
            }}
            x1="233.048"
            y1="287.066"
            x2="435.4"
            y2="287.066"
          />

          <line
            style={{
              fill: 'none',
              stroke: '#000000',
              strokeWidth: 30,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
            }}
            x1="233.048"
            y1="347.07"
            x2="435.4"
            y2="347.07"
          />

          <line
            style={{
              fill: 'none',
              stroke: '#000000',
              strokeWidth: 30,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
            }}
            x1="233.048"
            y1="407.074"
            x2="435.4"
            y2="407.074"
          />
        </g>

        <polygon
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 30,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
          }}
          points="&#10;&#9;&#9;&#9;340.557,15.001 171.445,97.131 340.557,97.131 &#9;&#9;"
        />
      </g>

      <rect
        x="233.048"
        y="167.059"
        style={{
          fill: 'none',
          stroke: '#000000',
          strokeWidth: 30,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
        width="202.352"
        height="60.004"
      />
    </g>
  </SvgIcon>
);

export default FlyerIcon;
