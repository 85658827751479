import { httpClient } from '../http/http-client';
import { PasswordChange, UserDetails } from './models';

const getUserDetails = (): Promise<UserDetails> => {
  return httpClient.get('/User');
};

const updateUserDetails = (updatedUser: UserDetails): Promise<UserDetails> => {
  return httpClient.put('/User', updatedUser);
};

const uploadAvatar = (formData: FormData): Promise<{ avatarUrl: string }> => {
  return httpClient.post('/User/logo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updatePassword = (request: PasswordChange): Promise<UserDetails> => {
  return httpClient.put('/User', request);
};

export { getUserDetails, updateUserDetails, uploadAvatar, updatePassword };
