import React from 'react';
import { Button, Drawer, Box } from '@mui/material';
import { drawerBody } from '../../listings/components/DetailsStyle.sx';
import ClearIcon from '@mui/icons-material/Clear';
import { User, Direct, Call } from 'app/components/SvgIcons';

import { UserInfo } from '../../listings/core/_models';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userInfo: UserInfo;
}

const Details = ({ isOpen, onClose, userInfo }: Props) => {
  return (
    <>
      <Drawer anchor="right" variant="temporary" open={isOpen} onClose={onClose}>
        <Box sx={drawerBody}>
          <div className="d-flex align-items-center justify-content-end mb-4">
            <Button className="p-2 min-w-10px" variant="outlined" size="small" onClick={onClose}>
              <ClearIcon />
            </Button>
          </div>

          <div className="d-flex align-items-center justify-content-between mb-4">
            <User className="fs-5" />
            <h3 className="fw-bold fs-5 text-black p-0 m-0 ms-2">{userInfo.fullName}</h3>
          </div>

          <div className="d-flex align-items-center justify-content-between py-3">
            <div className="d-flex align-items-center">
              <Direct className="fs-5" />
              <span className="fs-6 text-black p-0 m-0 ms-2">Email</span>
            </div>
            <span className="fs-6 text-black ps-1">{userInfo.email}</span>
          </div>

          <div className="d-flex align-items-center justify-content-between py-3">
            <div className="d-flex align-items-center">
              <Call className="fs-5" />
              <span className="fs-6 text-black p-0 m-0 ms-2">Phone Number</span>
            </div>
            <span className="fs-6 text-black ps-1">{userInfo.phoneNumber}</span>
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default Details;
