import { Card, styled, Tab, Tabs } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'white',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    height: 2
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 400,
  fontSize: '1rem',
  color: theme.palette.text.secondary,
  backgroundColor: 'white',
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: 500,
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    boxShadow: 'none'
  },
  border: 'none',
  '&:hover': {
    backgroundColor: 'white',
    color: theme.palette.text.primary
  },
  '&.MuiTab-root': {
    border: 'none',
    outline: 'none',
    boxShadow: 'none'
  }
}));

export const RequestContainer = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  overflow: 'hidden',
}));

export const PropertyImage = styled('img')({
  width: 112,
  height: 84,
  objectFit: 'cover',
  borderRadius: 8,
});

