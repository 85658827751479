import React, { useState } from 'react';
import { Box } from '@mui/material';
import { StyledTab, StyledTabs } from './property-requests.sx';
import { ActivityStatus } from '../core/_models';
import RequestList from './property-request-list';

const PropertyRequests = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setPageNumber(1); // Reset page when changing tabs
  };

  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  return (
    <Box sx={{ p: 3 }}>
      <StyledTabs value={activeTab} onChange={handleTabChange} textColor="inherit">
        <StyledTab label="Requests" />
        <StyledTab label="Accepted" />
        <StyledTab label="Deleted" />
      </StyledTabs>

      <TabPanel value={activeTab} index={0}>
        <RequestList
          status={ActivityStatus.PENDING}
          pageNumber={pageNumber}
          showActions
          onPageChange={handlePageChange}
        />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <RequestList status={ActivityStatus.ACCEPTED} pageNumber={pageNumber} onPageChange={handlePageChange} />
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <RequestList status={ActivityStatus.CANCELED} pageNumber={pageNumber} onPageChange={handlePageChange} />
      </TabPanel>
    </Box>
  );
};

function TabPanel({ children, value, index }: { children: React.ReactNode; index: number; value: number }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`request-tabpanel-${index}`}
      aria-labelledby={`request-tab-${index}`}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

export default PropertyRequests;
