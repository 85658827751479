import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { Button } from '@mui/material';

export const StyledCard = styled(Card)({
  marginBottom: '8px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
  overflow: 'visible',
});

export const DetailButton = styled(Button)({
  backgroundColor: '#FFD700',
  color: '#000',
  textTransform: 'none',
  // borderRadius: '16px',
  padding: '8px 14px',
  minWidth: 'unset',
  '&:hover': {
    backgroundColor: '#FFD700',
    opacity: 0.9,
  },
});
