import React from 'react';
import { Box, Pagination, Paper, Stack, Typography } from '@mui/material';
import LoaderSpinner from './LoaderSpinner';
import { useQuery } from 'react-query';
import { InboxOutlined } from '@mui/icons-material';
import RequestCard from './property-request-card';
import { Response } from '../../../../constants/models';
import { QUERIES } from '../../../../constants/consts';
import { getActivities } from '../core/_requests';
import { ActivityFilter, ActivityLog, ActivityStatus } from '../core/_models';

interface Props {
  status: ActivityStatus;
  pageNumber: number;
  showActions?: boolean;
  onPageChange: (page: number) => void;
}

const RequestList = ({ status, pageNumber, showActions = false, onPageChange }: Props) => {
  const filter: ActivityFilter = {
    pageNumber,
    pageSize: 10,
    status,
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    onPageChange(page);
  };

  const {
    data: response,
    error,
    isLoading,
  } = useQuery<Response<ActivityLog[]>>([`${QUERIES.ACTIVITIES}`, status, pageNumber], () => getActivities(filter), {
    keepPreviousData: true,
  });

  if (isLoading) {
    return <LoaderSpinner />;
  }

  if (error) {
    return (
      <Typography color="error" sx={{ textAlign: 'center' }}>
        Error loading listings
      </Typography>
    );
  }

  if (!response?.values?.length) {
    return <NoRequestAvailable status={status} />;
  }

  return (
    <Stack spacing={2}>
      {response?.values?.map((activity) => (
        <RequestCard status={status} key={activity.id} request={activity} showActions={showActions} />
      ))}

      {response?.totalCount !== 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="body2" color="text.secondary">
              Total: {response?.totalCount} items
            </Typography>
            <Pagination
              page={pageNumber}
              count={response?.totalPages || 1}
              onChange={handlePageChange}
              color="primary"
              showFirstButton
              showLastButton
              disabled={isLoading}
            />
          </Box>
        </Box>
      )}
    </Stack>
  );
};

export default RequestList;

const NoRequestAvailable = ({ status }: { status: ActivityStatus }) => (
  <Paper
    sx={{
      textAlign: 'center',
      py: 6,
      px: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      backgroundColor: 'background.default',
    }}
  >
    <InboxOutlined sx={{ fontSize: 48, color: 'text.secondary' }} />
    <Typography variant="h6" color="text.secondary">
      No Requests Available
    </Typography>
    <Typography variant="body2" color="text.secondary">
      {status === ActivityStatus.PENDING
        ? 'There are no pending requests at the moment'
        : status === ActivityStatus.ACCEPTED
          ? 'No requests have been accepted yet'
          : 'No requests have been deleted'}
    </Typography>
  </Paper>
);
