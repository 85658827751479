import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ViewDocumentIcon = (props: SvgIconProps) => (
  <SvgIcon
    sx={{
      width: '16px',
      height: '16px',
      '& path': {
        fill: 'currentColor',
      },
    }}
    viewBox="0 0 24 24"
    fontSize="inherit"
    {...props}
  >
    <g clipRule="evenodd" fill="rgb(0,0,0)" fillRule="evenodd">
      <path d="m2.25 4.5c0-1.24264 1.00736-2.25 2.25-2.25h15c1.2427 0 2.25 1.00737 2.25 2.25v7.5c0 .4142-.3358.75-.75.75s-.75-.3358-.75-.75v-7.5c0-.41422-.3358-.75-.75-.75h-15c-.41421 0-.75.33579-.75.75v15c0 .4142.33578.75.75.75h7.5c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-7.5c-1.24263 0-2.25-1.0073-2.25-2.25z" />
      <path d="m16 13.75c-1.2426 0-2.25 1.0074-2.25 2.25s1.0074 2.25 2.25 2.25 2.25-1.0074 2.25-2.25-1.0074-2.25-2.25-2.25zm-3.75 2.25c0-2.0711 1.6789-3.75 3.75-3.75s3.75 1.6789 3.75 3.75-1.6789 3.75-3.75 3.75-3.75-1.6789-3.75-3.75z" />
      <path d="m17.9144 17.5315c.2587-.3235.7307-.3759 1.0541-.1172l2.5 2c.3235.2588.3759.7308.1172 1.0542-.2588.3235-.7307.3759-1.0542.1171l-2.5-2c-.3234-.2587-.3759-.7307-.1171-1.0541z" />
      <path d="m6.25 8c0-.41421.33579-.75.75-.75h10c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-10c-.41421 0-.75-.33579-.75-.75z" />
      <path d="m6.25 12c0-.4142.33579-.75.75-.75h4c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-4c-.41421 0-.75-.3358-.75-.75z" />
    </g>
  </SvgIcon>
);

export default ViewDocumentIcon;
