import { Box, keyframes, useTheme } from '@mui/material';
import React from 'react';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

interface LoaderSpinnerProps {
  size?: number; // Size in pixels
  thickness?: number; // Border thickness in pixels
  color?: string; // Override color
  speed?: number; // Animation duration in seconds
}

const LoaderSpinner = ({ size = 32, thickness = 2, color, speed = 0.5 }: LoaderSpinnerProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
      }}
    >
      <Box
        sx={{
          width: size,
          height: size,
          border: `${thickness}px solid`,
          borderColor: 'white',
          borderTopColor: color || theme.palette.primary.main,
          borderRadius: '50%',
          animation: `${spin} ${speed}s linear infinite`,
          '@media (prefers-reduced-motion: reduce)': {
            animation: 'none',
          },
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
      />
    </Box>
  );
};

export default LoaderSpinner;
