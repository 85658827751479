import React, { useState } from 'react';
import { Button, Avatar, IconButton, Box, Typography, CircularProgress } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import logger from '../../../utils/logger';
import { uploadAvatar } from '../../../api/account-settings/requests';
import CloseIcon from '@mui/icons-material/Close';

const AvatarUploader = ({
  currentAvatar,
  onSave,
  onCancel,
}: {
  currentAvatar: string;
  onSave: (newAvatar: string) => void;
  onCancel: () => void;
}) => {
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(currentAvatar);
  const [file, setFile] = useState<File | null>(null);
  const { mutate, isLoading } = useMutation(uploadAvatar, {
    onSuccess: (data) => {
      toast.success('Avatar uploaded successfully');
      onSave(data.avatarUrl);
    },
    onError: (error: any) => {
      logger.error('Error uploading avatar:', error);
      toast.error('Failed to upload avatar. Please try again.');
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      setFile(selectedFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append('avatar', file);
      mutate(formData);
    } else {
      toast.error('No file selected');
    }
  };

  return (
    <Box>
      <Box sx={{ position: 'relative', display: 'inline-block' }}>
        <Avatar src={typeof preview === 'string' ? preview : ''} sx={{ width: 80, height: 80 }} />
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="avatar-upload"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="avatar-upload">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              bgcolor: 'rgba(255, 255, 255, 0.7)',
              p: 1,
              '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.8)' },
            }}
          >
            <PhotoCamera />
          </IconButton>
        </label>
      </Box>
      {preview && (
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={20} />}
          >
            Save
          </Button>
          <Button variant="text" color="secondary" onClick={onCancel} startIcon={<CloseIcon />}>
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AvatarUploader;
