import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { SearchField } from './components/ny-listings.sx';
import { useNavigate } from 'react-router-dom';
import PropertyRequests from './components/property-requests';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { black, gray7 } from '../../../components/ThemeProvider/colors';

const ListingsRequestsPage = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <>
      <Box sx={{ width: '30%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton
          sx={{ p: '8px', border: `1px solid ${gray7}`, borderRadius: '8px', mr: 2 }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNewIcon sx={{ width: '14px', height: '14px', fill: black }} />
        </IconButton>
        <SearchField
          fullWidth
          size="small"
          placeholder="Search My Listings"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: <Search sx={{ color: 'grey.600', mx: 2 }} />,
          }}
        />
      </Box>
      <PropertyRequests />
    </>
  );
};

export { ListingsRequestsPage };
