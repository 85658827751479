import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DeleteTrashIcon = (props: SvgIconProps) => (
  <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="none"
      d="M14 3.98665C11.78 3.76665 9.54667 3.65332 7.32 3.65332C6 3.65332 4.68 3.71999 3.36 3.85332L2 3.98665"
      stroke="#4C4F59"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path fill="none"
      d="M5.66663 3.3135L5.81329 2.44016C5.91996 1.80683 5.99996 1.3335 7.12663 1.3335H8.87329C9.99996 1.3335 10.0866 1.8335 10.1866 2.44683L10.3333 3.3135"
      stroke="#4C4F59"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path fill="none"
      d="M12.5667 6.09326L12.1334 12.8066C12.06 13.8533 12 14.6666 10.14 14.6666H5.86002C4.00002 14.6666 3.94002 13.8533 3.86668 12.8066L3.43335 6.09326"
      stroke="#4C4F59"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.88672 11H9.10672" stroke="#4C4F59" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />

    <path d="M6.33337 8.3335H9.66671" stroke="#4C4F59" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
  </SvgIcon>
);
export default DeleteTrashIcon;
