import React from 'react';
import { Box, Typography } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { QUERIES } from '../../../../constants/consts';
import { getImageUrl, getMyListings } from '../core/_requests';
import defaultImage from 'assets/default-image.png';
import { getPropertyTypeName, Property } from '../core/_models';
import { AvailableButton, EditButton, ListingImage, StyledCard } from './ny-listings.sx';

interface MyListingsProps {
  searchQuery?: string;
}

const MyListings = ({ searchQuery }: MyListingsProps) => {
  const { data: listings, error, isLoading } = useQuery(`${QUERIES.MYPROPERTY_LIST}-listings`, getMyListings);
  const filteredListings = React.useMemo(() => {
    if (!listings) {
      return [];
    }

    const query = searchQuery.toLowerCase().trim();
    if (!query) {
      return listings;
    }

    return listings.filter(
      (listing: Property) =>
        listing.address?.toLowerCase().includes(query) || listing.name?.toLowerCase().includes(query)
    );
  }, [listings, searchQuery]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={300}>
        <div
          style={{
            width: 32,
            height: 32,
            border: '2px solid #f3f3f3',
            borderTop: '2px solid #3498db',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
          }}
        />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        Error loading listings
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Typography variant="h6" sx={{ pb: 1 }}>
          My Listings
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {filteredListings.length > 0 ? (
          filteredListings.map((listing: Property) => (
            <StyledCard key={listing.id}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <ListingImage
                    src={getImageUrl(listing.image)}
                    alt={listing.address}
                    onError={(e) => {
                      e.currentTarget.src = defaultImage;
                    }}
                  />
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                      <LocationOn sx={{ fontSize: 16, color: 'grey.500' }} />
                      <Typography variant="body2" color="text.primary">
                        {listing.address}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        {getPropertyTypeName(listing.type)}
                      </Typography>
                      <Typography variant="body2">${Number(listing.price).toLocaleString()}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
                  <AvailableButton endIcon={<span>›</span>}>Available</AvailableButton>
                  <EditButton variant="outlined">Edit</EditButton>
                </Box>
              </Box>
            </StyledCard>
          ))
        ) : (
          <Typography color="text.secondary" textAlign="center">
            No listings found matching &quot;{searchQuery}&quot;
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MyListings;


