import React, { useState } from 'react';
import { Button, Drawer, Box, Typography, IconButton, Avatar, Stack, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UserDetails } from '../../../api/account-settings/models';
import { drawerBody } from './details-style.sx';
import DeleteTrashIcon from '../../../components/SvgIcons/DeleteTrashIcon';
import { useMutation, useQueryClient } from 'react-query';
import { updateUserDetails } from '../../../api/account-settings/requests';
import toast from 'react-hot-toast';
import logger from '../../../utils/logger';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userDetails: UserDetails;
}

const UpdateCompanyDrawer = ({ isOpen, onClose, userDetails }: Props) => {
  const [formData, setFormData] = useState<UserDetails>(userDetails);
  const queryClient = useQueryClient();

  const mutation = useMutation(updateUserDetails, {
    onSuccess: () => {
      toast.success('Company name updated successfully');
      queryClient.invalidateQueries('userDetails');
      onClose();
    },
    onError: (error: any) => {
      logger.error('Error updating company name:', error);
      toast.error('Failed to update company details. Please try again.');
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Drawer anchor="right" variant="temporary" open={isOpen} onClose={onClose}>
      <Box sx={drawerBody}>
        {/* Header */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
          <Typography variant="h6">Company Settings</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Avatar Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Avatar src={userDetails.companyLogo} sx={{ width: 80, height: 80, mr: 2 }} />
          <Box sx={{ ml: 2 }}>
            <Button variant="outlined" size="small" sx={{ mr: 1 }}>
              Edit
            </Button>
            <Button variant="text" size="small" startIcon={<DeleteTrashIcon />}>
              Remove
            </Button>
          </Box>
        </Box>

        {/* Form Fields */}
        <Stack spacing={3} sx={{ flex: 1 }}>
          <TextField
            name="companyName"
            defaultValue={userDetails.companyName}
            onChange={handleInputChange}
            label="Copmany name"
            variant="outlined"
          />
        </Stack>

        {/* Update Button */}
        <Box sx={{ mt: 4 }}>
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={() => mutation.mutate(formData)}
            sx={{
              bgcolor: 'grey.300',
              color: 'text.primary',
              '&:hover': {
                bgcolor: 'grey.400',
              },
              py: 1.5,
            }}
          >
            Update
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default UpdateCompanyDrawer;
